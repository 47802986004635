/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { hookStore } from '~/modules/SDK/Signalr/useSignalrStart2_1';
export const useSignalrSymbolsWatcher2 = () => {
    useEffect(() => {
        if (!signalrStore2.state.isConnected)
            return;
        hookStore.invokeSubscribe();
    }, [
        signalrStore2.state.isConnected,
        signalrStore2.subscriptions.BidAskSymbols,
        signalrStore2.subscriptions.QuoteSymbols,
        signalrStore2.subscriptions.QuoteTopics,
        signalrStore2.subscriptions.TickSymbols,
        signalrStore2.subscriptions.TradeInfoTopics,
    ]);
};
