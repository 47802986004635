/* eslint-disable react-hooks/exhaustive-deps */
import { defaults, values } from 'lodash';
import { useEffect } from 'react';
import { useMount } from 'react-use';
import { proxy } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalrConnectionWatcher2 } from '~/modules/SDK/Signalr/useSignalrConnectionWatcher2';
import { useSignalrSetUid2 } from '~/modules/SDK/Signalr/useSignalrSetUid2';
import { useSignalrSymbolsWatcher2 } from '~/modules/SDK/Signalr/useSignalrSymbolsWatcher2';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
export const hookStore = proxy({
    /** `useStart(options) 的 options` */
    options: {
        /** 預設 30 秒。檢查一次連線，若斷線則自動重新連線 */
        reconnectCheckInterval: (30 * 1000),
    },
    connect: async () => {
        return await signalrStore2.start();
    },
    invokeSubscribe: async () => {
        return await signalrStore2.invokeSubscriptions();
    },
});
/** # v2.1 版本 */
export const useSignalrStart2_1 = (options) => {
    useMount(() => {
        debugAPI.signalrV2.log(`.useSignalrStart2_1()`);
    });
    useEffect(() => {
        hookStore.options = defaults(options, hookStore.options);
    }, [...values(options)]);
    useSignalrConnectionWatcher2();
    useSignalrSetUid2();
    useSignalrSymbolsWatcher2();
};
